.Team {

  padding: 10px;
}

@media (max-width: 400px) {
  .Team {

  }
}
@media (min-width: 401px) {
  .Team {
    width: 60%;
  }
}