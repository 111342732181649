
.headShot {
  position: relative;
  background-color: #ff00ff;
  height: 100%;
  width: 100%;
}

.headShot .speakerName  {
  text-align: center;
  display:block;
  position:absolute;
  bottom:0px;
  background-color: #0009;
  height: 1.2em;
  width: 100%;
  margin: 0 2px 0 2px;
}

.headShot .layers {
  max-width: 800px;
  max-height: 800px;


  animation-name: none;
  width: 120%;
  height: 120%;
  overflow: hidden;
}

.headShot .layers .imgcontainer{
  max-width: 800px;
  max-height: 800px;
  min-width: 100%;
  min-height: 100%;

  position: absolute;
  width: 110%;
  height: 110%;
  left: -5%;
  top: -5%;
  animation-name: none;
}
.headShot .layers .imgcontainer img{
  width: 100%;
  height: 100%;

}
 .headShot .layers .animated.Bioreactor,
 .headShot .layers .animated.Communications,
 .headShot .layers .animated.Bonus
 {
  animation: 2s linear 0.25s infinite reverse none running none;
  animation-name: bounce_body;
}

  .headShot .layers .animated.Manipulators
 {
  animation: 2s linear 0.25s infinite reverse none running none;
  animation-name: bounce_arms;
}

 .headShot .layers .animated.Locomotion
 {
  animation: 2s linear 0.25s infinite reverse none running none;
  animation-name: bounce_legs;
}

 .headShot .layers .animated.Optical_Sensors,
 .headShot .layers .animated.Processing_Unit {
  animation: 2s linear 0.25s infinite reverse none running none;
  animation-name: bounce_head;
}

.headshot .layers .frozen {
  animation-name: none;
}




@keyframes bounce_head {

   0% {transform: translateX(-1.5%) translateY(3%);}
   25% {transform: translateX(0) translateY(-1%);}
   50% {transform: translateX(1.5%) translateY(2%);}
   75% {transform: translateX(0) translateY(-1%);}
   100% {transform: translateX(-1.5%) translateY(3%);}
}

@keyframes bounce_body {

    0% {transform: translateX(-1%) translateY(1.5%);}
    25% {transform: translateX(0) translateY(-1.5%);}
    50% {transform: translateX(1%) translateY(1.5%);}
    75% {transform: translateX(0) translateY(-1.5%);}
    100% {transform: translateX(-1%) translateY(1.5%);}
}

@keyframes bounce_arms {
  0% {transform: translateX(-1%) translateY(1.5%) rotate(-5deg);}
  25% {transform: translateX(0) translateY(-1.5%) rotate(0deg);}
  50% {transform: translateX(1%) translateY(1.5%) rotate(5deg);}
  75% {transform: translateX(0) translateY(-1.5%) rotate(0deg);}
  100% {transform: translateX(-1%) translateY(1.5%) rotate(-5deg);}
}
@keyframes bounce_legs {

   0% {transform: translateX(0%) translateY(-0.5%);}
   25% {transform: translateX(0) translateY(0.5%);}
   50% {transform: translateX(-0%) translateY(-0.5%);}
   75% {transform: translateX(0) translateY(0.5%);}
   100% {transform: translateX(0%) translateY(-0.5%);}
}


