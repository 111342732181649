.ContextDrawer .hamburger {
  position: absolute;
  top:0;
  right:0;
  z-index: 2;
  pointer-events: all;
}

.ContextDrawerContents {
  min-width: 30vw;
  width: 300px;
}

.ContextDrawerContents .hamburger {
  position: relative;
  top:0;
  left:0;
  z-index: 2;
  pointer-events: all;
}
