.Home {

}
.Home .logoWrapper{

}
.Home .logo {
  width: 100%;
  object-fit: contain;
}

.Home .header {
  position:sticky;
  top:0;
  height: 50px;
  padding: 10px;
  background-color: #000;
  width: 100%;
   box-sizing: border-box;
}

.Home .hero {
  margin: auto;
  margin-bottom: 100px;

  min-width: fit-content;
  padding: 10px;
}

.Home .about {
  margin: auto;
  margin-bottom: 50px;

  padding: 10px;
}

@media (max-width: 400px) {
  .Home .hero {
    width: 80%;
  }
  .Home .about{
    width: 100%;
  }
  .Home .navButton {
      font-size: 1.0em;
    }
}
@media (min-width: 401px) {
  .Home .hero {
    width: 50%;
  }
  .Home .about{
    width: 70%;
  }
  .Home .navButton {
    font-size: 1.3em;
  }
}


.Home .header .smallLogo{
  height: 50px;
}

.Home .details {
  font-size: 1.3em;
}

