
.NoSignal{
  width: 100%;
  height: 100%;
}

.NoSignal .MuiDialog-paper{
  overflow: visible;

}

.NoSignal .MuiDialog-paperScrollPaper {
  max-width: 45vw;
  max-height: 45vw;
  width:100%;
  height: 100%;
}
#wrapper {

  width: 100%;
  height:100%;
  display: flex;
    flex-direction: column;
    position:relative;

}
#noSignalCanvas {
  width: 100%;
  height: 100%;
}

.NoSignal .label {
    position: absolute;
    z-index: 0;
    top: 40%;
    left: 6vw;
    font-size: 5vw;
}