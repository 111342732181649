.PlanetGenesis .MuiDialog-paper{
  overflow: visible;

}

.genesisDialog {
  display: flex;
  flex-direction: column;
  max-width:95vw;
  width: inherit;

}

.PlanetGenesis video {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
