.Header {
  height: 50px;
  padding: 10px;
  position: relative;
  z-index: 1;
  vertical-align: middle;
  pointer-events: none;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  overflow: hidden;

}

.Header a {
  pointer-events: all;
}

.Header .smallLogo {
  float: left;
}

.Header .right-buttons {
  display: flex;
  align-items: center;
}

.Header .twitter-icon {
  padding: 6px 0 6px 0;
  height: 1.5em;
}

.Header .smallLogo.hide {
  opacity: 0%;
}

.Header .breadCrumb {
    display: block;
    float: left;
    vertical-align: middle;
    color: white;
    padding: 15px 0;
    margin-left: 20px;
    pointer-events: all;
}

.Header .breadCrumb .divider {
  float: left;
  margin-right: 20px;
}

.Header .breadCrumb a {
  color: white;
}

.Header .wallet {
    z-index: 2;
    pointer-events: all;
}

.Header .info {
    z-index: 2;
    pointer-events: all;
}
