#root {

}
.Ship {
  display: grid;
  grid-template: 256px minmax(256px, 1fr) / min-content minmax(256px, 1fr);
  grid-gap: 2vmax;
}


.Ship .shipFrame{
  width: 256px;
  height: 256px;
  align-items:center;
  justify-content:center;
  border: 2px solid #0f0;
  border-radius: 50% 50% 10px 10px;
  overflow:hidden;
}

.Ship .shipFrame img {
  aspect-ratio: 1;
  max-width: 256px;
  max-height: 256px;
  border-radius: 10px;
}

.panel {
  border: 1px solid #0f09;

    border-radius: 10px;
    position:relative;
  background-color:#000;

}

.description {
padding:2vmax;
}

.panel::after {
    animation: 1.0s cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s infinite normal none running glitch;

}
.panel::before {
    animation: 1.0s cubic-bezier(0.22, 0.61, 0.36, 1) 0.5s infinite reverse none running glitch;

}



.panel:after, .panel:before{
    border: 2px solid #0f09;
    border-radius: 10px;
    content: "";


    position: absolute;
    top: -1px;
    left: -1px;

    z-index: 10;
    height: 100%;
          width: 100%;
}

.shipFrame .panel:after, .shipFrame ,panel:before {
  height: 256px;
      width: 256px;
}

.Ship .operatorFrame {
  border: 2px solid #0f0;
  border-radius: 50% 50% 10px 10px;
  overflow: hidden;

}
.Ship .operatorFrame .headShot{
  width: 100%;
    height: 100%;
}

.Ship .operatorFrame .wrapper{
  width: 256px;
      height: 256px;

}

.Ship .operatorFrame .headShot .glitch{
  width: 260px;
      height: 260px;

}

.Ship .Speech .headShot {
    margin-top: 0;
  }

.description {
  display: grid;
  grid-template: auto / 1fr 1fr;
}

.Ship .panel img.pilotImage {
  width: 268px;
  height: 268px;
  position: absolute;
  top: -6px;
  left: -6px;

  animation: 2s ease 0.25s infinite reverse none running bounce_body;
}