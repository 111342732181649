
.GalaxyMap3d {
  position:relative;
  width: 100%;
  height: 100%;


}


#galaxyCanvas {

  width: 100%;
  height: 100%;
  touch-action: none;
}

#galaxyMap {
  width: 100%;
  height: 100%;
  touch-action: none;
}