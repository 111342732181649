html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: LazenbyCompLiquid, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #fff;
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #0f08;
}

::-webkit-scrollbar-thumb {
    background: #0f0;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
    background: #000;
}

p, span {
  font-family: 'Poppins', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glitchPanel {
  border: 1px solid #0f09;
    box-shadow:
                    inset 20px 0 80px #202,   /* inner left magenta short */
                    inset -20px 0 80px #f0f,  /* inner right cyan short */
                    inset 20px 0 256px #303,  /* inner left magenta broad */
                    inset -20px 0 256px #033, /* inner right cyan broad */
                    0px 0px 1px 1px #0f0;


  border-radius: 10px;
}

.glitchPanel::after {
    animation: 2.0s cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s infinite normal none running glitch;

}

.glitchPanel::before {
    animation: 1.0s cubic-bezier(0.22, 0.61, 0.36, 1) 0.5s infinite reverse none running glitch;

}


.glitchPanel:after, .glitchPanel:before{
    border: 2px solid #0f09;
    border-radius: 10px;
    content: "";
    height: 100%;
    width: 100%;

    position: absolute;
    top: -1px;
    left: -1px;

    z-index: 10;
}

@keyframes glitch {
  0% {
      opacity: 1;
      border: 1px solid #0f0;
      -webkit-transform: scale(100%);
      -ms-transform: scale(100%);
      transform: scale(100%) translate(2px, -3px);
      z-index: -1;
  }

  17% {
      opacity: 0.5;
      border: 2px solid #f0f;
      -webkit-transform: rotate(-0.5deg) scale(98%);
      -ms-transform: rotate(-0.5deg) scale(98%);
      transform: rotate(-0.5deg) scale(100%) translate(-2px, 2px);

  }


  33% {
      opacity: 0.3;
      border: 1px solid #0ff;
      -webkit-transform: rotate(-0.5deg);
      -ms-transform: rotate(-0.5deg);
      transform: rotate(-0.5deg) translate(3px, 3px);
  }

  50% {
        opacity: 0.8;
        border: 3px solid #f0f;
        -webkit-transform: rotate(0.5deg) scale(100%);
        -ms-transform: rotate(0.5deg) scale(100%);
        transform: rotate(0.5deg) scale(100%) translate(-2px, -2px);
    }
  60% {
          opacity: 0.5;
          border: 1px solid #0f0;
          -webkit-transform: rotate(-0.5deg);
          -ms-transform: rotate(-0.5deg);
          transform: rotate(-0.5deg) translate(3px, 3px);
      }

  100% {
      opacity: 0.5;
      border: 3px solid #0ff;
      -webkit-transform: rotate(0.5deg) scale(100%);
      -ms-transform: rotate(0.5deg) scale(100%);
      transform: rotate(0.5deg) scale(100%) translate(-2px, 2px);
      z-index: -1;
  }
}
