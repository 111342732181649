#planetCanvas {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    touch-action: none;
}

.Planet .title {
    position:absolute;
    color: #fff;
    z-index: 1;
    font-family: LazenbyCompLiquid;
    padding: 20px;
    pointer-events: none;
}

.Planet .controls {
  position: absolute;
  z-index: 1;
}