@font-face{
    font-family: LazenbyCompLiquid;
    src: url("./fonts/LazenbyCompLiquid.otf") format("opentype");
}

.Container .universe-loading {
  z-index: 1;
  height: 65px;
  opacity: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.Container .universe-loading.thin {
  top: 65px;
  height: 5px;
}

.Container .content {
  padding: 0;
}

.logo-container {
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;

  z-index: 10;
}

div.fade-in, .fade-in img{
  animation-name: fade-in;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
}

div.fade-out, .fade-out img {
  animation-name: fade-out;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.logo-container img {
  position: relative;
  z-index: 10;
  max-width: 100%;
  max-height: 80%;

}
#spaceCanvas {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    touch-action: none;
}

@keyframes fade-in {
  0% {opacity: 0%;}
  100% {opacity: 100%;}

}

@keyframes fade-out {
  0% {opacity: 100%;}
  100% {opacity: 0%;}

}
@keyframes disappear {
  0% {opacity:100%;}
  99% {opacity:100%;}
  100% {opacity: 0%;}

}