.PlanetMinter {
  padding: 10px;
}
@media (max-width: 400px) {
  .PlanetMinter  {
    width: 95%;
  }

}
@media (min-width: 401px) {
  .PlanetMinter  {
    width: 60%;
  }

}