
.GalaxyMap2d {
  position:relative;
  margin: auto;

}

.GalaxyMap2d .solarSystem {
  position: absolute;
  mix-blend-mode: lighten;
  cursor: pointer;
}

.GalaxyMap2d .solarSystem canvas {
  width: 100%;
  height: 100%;
}