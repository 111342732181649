.ShipMinter {
  padding: 10px;
}
@media (max-width: 400px) {
  .ShipMinter  {
    width: 95%;
  }

}
@media (min-width: 401px) {
  .ShipMinter  {
    width: 60%;
  }

}