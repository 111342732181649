
.WalletDialog{
  width: 100%;
  height: 100%;
  background: none;
  overflow-y: auto;
}

.WalletDialog .MuiDialog-paper{
  overflow: visible;
  background: none;
  box-shadow: none;
  min-width: 380px;
}


.WalletDialog .wrapper {
  box-shadow: none;
  width: 100%;
  height:100%;
  display: flex;
    flex-direction: column;
    position:relative;
}

@media all and (orientation: portrait) {
  .WalletDialog .MuiDialog-paper{

    margin: 0;
  }

}