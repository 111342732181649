
.pilot {
  width: 50%;

}



.Pilot .speakerName {
  text-align: center;
    display:block;
    position:absolute;
    bottom:0px;
    background-color: #0009;
    height: 1.2em;
    width: 100%;
    margin: 0 2px 0 2px;
}


