
.Speech {
  height: 100%;
}
.Speech .MuiDialog-paper {
  position: absolute;
  top: 0;
  height: 100%;
}

.Speech .content {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.Speech .speaker {
  max-height: min(65vmin, 300px);
  max-width: min(65vmin, 300px);
  aspect-ratio: 1;
  border-radius: 10px 0 0 10px;
  border: 2px solid #0f09;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position:relative;
  flex-shrink: 1;
  overflow:hidden;
  box-shadow: 0px 0px 20px 0px #0f08;
}

.Speech .paragraphWrapper {
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: fit-content;
  border-radius: 0 10px  10px 0;
  box-shadow: 0px 0px 20px 0px #0f08;
}

.Speech .speaker {
  flex-basis: fit-content;
  flex-grow: 1;
  flex-shrink: 2;
}

@media all and (orientation: portrait) {
.Speech .MuiDialog-paperWidthSm{
    width: 100%;
    max-width: 100%;
  }

  .Speech .content .speaker {
    object-fit: contain;
    border-radius: 10px;
  }

  .Speech .content {
    display: flex;
    flex-direction: column;
  }


  .Speech .paragraphWrapper {
    margin-top: 5px;
    height:100%;
    border-radius: 10px;

  }
  .Speech .paragraph  {
      padding: 0 10px 0 10px
    }
  .Speech .content  .speaker {
    margin-top: 0;
  }

  .Speech .empty {
    height: 0;
  }

}

@media all and (orientation: landscape) {
  .Speech .MuiDialog-paperWidthSm  {
    width: 80%;
    max-width: 80%;
  }
  .Speech .content {
    display: flex;
    flex-direction: row;
  }

  .Speech .paragraphWrapper {

    margin-top: 5vh;
    height: 100%;

  }
  .Speech .paragraph  {
    padding: 20px 20px 0 20px
  }

  .Speech .content  .speaker {
    margin-top: 5vh;
  }

  .Speech .panel-wrapper img {
    object-fit: contain;
    width: 23vw;
    height: 23vw;
  }

  .Speech .empty {
    height: 44px;
  }
}


.Speech .content  .speaker {

}
@keyframes bounce {

   0% {transform: translateX(-4px) translateY(6px);}
   25% {transform: translateX(0) translateY(-6px);}
   50% {transform: translateX(4px) translateY(6px);}
   75% {transform: translateX(0) translateY(-6px);}
   100% {transform: translateX(-4px) translateY(6px);}
}

.Speech .speaker img {
  #max-width: 256px;
  #max-height: 256px;
  width:100%;
  height:100%;
  animation-name: bounce;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}


.Speech .panel-wrapper  {

  overflow: hidden;
  grid-template-rows: 100% 0%;
  border: 2px solid #0f09;



    border-radius: 10px;

}

.Speech .speaker .speakerName {
  text-align: center;
  display:block;
  position:absolute;
  bottom:0px;
  background-color: #0009;
  height: 1.2em;
  width: 100%;
  margin: 0 2px 0 2px;
}

.Speech .paragraphWrapper {
  border: 2px solid #0f09;
  font-size: 1.5em;
  max-height: 300px;
  background-color: #000;
  position:relative;
}
.Speech .paragraph {

  font-family: 'Baloo Bhaijaan 2', cursive;
  position:absolute;
  bottom: 50px;
  top: 0;
  overflow-y: auto;
}

.Speech .pressAnyKey {

   position: absolute;
   bottom:10px;
   right: 10px;
}
