#planetCanvas {
  cursor: none;
}

.PlanetEditor {
  position:absolute;
  bottom: 2vh;
  right: 4vw;
  width: 100%;
  display: flex;
  justify-content:right;
}

.PlanetEditor .button {
  width: 64px;
  z-index: 1;
}

.PlanetEditor .button img{
width: 64px;
}



.PlanetEditor  .label {
  z-index: 1;
  width: 50px;
  display: block;
}


.PlanetEditor .content {
  z-index: 1;
}

.PlanetEditor .EditorOptions {
  width: 250px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 50px 1fr;
  justify-content: left;
  align-items: center;

}

