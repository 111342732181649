#solarSystemCanvas {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    touch-action: none;
}

.SolarSystem .infoPanel {
  position: relative;
  z-index: 1;
  padding: 20px;
  pointer-events: none;
}